
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
      tabPosition: "left",
      currentPage1:1,
      currentPage2:1,
      messList: [
        {
          id: 1,
          mess:
            "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess:
            "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess:
            "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess:
            "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
    };
  },
  methods: {
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
      handleSizeChange(val:any) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val:any) {
        console.log(`当前页: ${val}`);
      },
       handleSizeChange2(val:any) {
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange2(val:any) {
        console.log(`当前页: ${val}`);
      }
  },
});
